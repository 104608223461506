import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const EndlessPossibilitiesLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Rosen"
    institution="Endless Possibilities Acupuncture"
    referralCode="ENDLESS"
    physicianURL="https://acupuncturesantacruz.net/"
  />
)

export default EndlessPossibilitiesLandingPage
